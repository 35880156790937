<template>
  <v-card elevation="0" color="transparent">
    <div>{{ $t('components.shared.creditCard.title') }}</div>
    <div class="d-flex align-center py-5">
      <Bank :color="$vuetify.theme.dark ? '#ffffff' : '#000000'" />
      <span class="ml-5 font-weight-black small-text small-space">• • • •</span>
      <span class="ml-3 font-weight-black small-text small-space">• • • •</span>
      <span class="ml-3 font-weight-black small-text small-space">• • • •</span>
      <span class="ml-3 small-text">{{ number }}</span>
    </div>
  </v-card>
</template>

<script>
import Bank from '@/assets/icons/svg/Bank.vue';

export default {
  name: 'CreditCard',
  components: {
    Bank,
  },
  props: {
    number: {
      type: [Number, String],
      default: 'XXXX',
    },
  },
};
</script>

<style lang="scss" scoped>
.small-text {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 2px;
  &.small-space {
    letter-spacing: -1px;
  }
}
</style>
